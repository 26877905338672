import React from 'react';

function InputBox({ label, type, placeholder, value, onChange }) {
  return (
    <div className="mb-4">
      <label className="block text-[16px] lg:text-[24px] font-medium mb-2">{label}</label>
      <div className="relative">
        <input
          className="border-2 border-black w-full text-sm lg:text-xl py-4 px-4 focus:outline-none focus:shadow-outline"
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default InputBox;
