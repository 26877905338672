import DoubleText from "../base/DouleText";
import HeadText from "../base/HeadText";

import MapImg from "../../images/map2.png";
import BaseText from "../base/BaseText";

function TechSection() {
    return (
        <div className="flex xl:flex-row flex-col items-center justify-between">
            <div className="lg:w-1/2">
                <HeadText>Технико-экономические показатели</HeadText>
                <div className="flex flex-col sm:flex-row mt-14">
                    <div className="mr-5">
                        <DoubleText head="Общая площадь" text="7130,99 кв.м." />
                        <DoubleText head="Площадь участка" text="0,3740 га." />
                        <DoubleText head="Количество офисов" text="19 штук" />
                        <DoubleText
                            head="Высотная отметка проектируемого объекта"
                            text="16.4 м."
                        />
                    </div>
                    <div>
                        <DoubleText
                            head="Количество машиномест"
                            text="45 штук"
                        />
                        <DoubleText
                            head="Площадь офисов"
                            text="3420,78 кв.м."
                        />
                        <DoubleText
                            head="Вид разрешенного использования земельного участка"
                            text="Размещение объектов управленческой деятельности (4.1.0), постоянных и временных гаражей (4.9.0)."
                        />
                    </div>
                </div>
            </div>
            <div className="bg-[#E8E3DA] xl:p-4 2xl:p-11 rounded-3xl mt-4 lg:mt-0">
                <div className="flex flex-col 2xl:flex-col 2xl:items-center justify-center lg:max-w-[640px] 2xl:max-w-[550px]">
                    <img className="2xl:mb-6 p-4 xl:p-0" src={MapImg}></img>
                    <div className="flex 2xl:flex-col 2xl:justify-normal 
                    2xl:py-0 xl:py-40 p-4 
                    justify-between flex-col 
                    ml-0 xl:ml-4 2xl:ml-0">
                        <div className="2xl:mb-8 xl:mb-0 mb-8">
                            <BaseText opacity={false}>
                                В десятиминутной пешей доступности 
                                от Б.Дорогомиловской находятся все необходимые для
                                комфортной жизни сервисы и объекты: рестораны,
                                wellnes-клубы, кинотеатр Пионер, Дорогомиловский
                                рынок и гастрономический центр Eataly.
                            </BaseText>
                        </div>
                        <p className="text-[14px] font-medium">
                            На карте отображены точки общественного питания, их
                            рейтинг и популярность на основе отзывов и оценок
                            (источник: Яндекс).
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TechSection;
