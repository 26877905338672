import React from "react";
import HeadText from "../base/HeadText";

import FooterImage from "../../newImages/newFooterImg2.jpg";
import SemiColorfulText from "../base/SemiColorfulText";

import docs4 from "../../docs/docs1.pdf"
import docs1 from "../../docs/dog_arenda.pdf"
import docs2 from "../../docs/GPZY.pdf"
import docs3 from "../../docs/svid.pdf"

function FooterSection() {
    return (
        <div>
            <div className="flex flex-col lg:flex-row justify-between">
                <div>
                    <h1 className="opacity-60 text-[14px] lg:text-[20px] font-medium mb-4">
                        Информация о застройщике
                    </h1>
                    <div className="flex flex-col lg:flex-row justify-between">
                        <div className="mb-8 lg:mb-0">
                            <div className="mg-8 lg:mb-14">
                                <HeadText>Контакты <br/> <br/> </HeadText>
                            </div>
                            <div className="2xl:max-w-[500px]">
                                <div className="mb-8">
                                    <SemiColorfulText
                                        boldText="Общество с ограниченной ответственностью «Специализированный застройщик Б.Дорогомиловская 9к2»"
                                        basicText="Полное наименование: "
                                    />
                                </div>
                                <div className="mb-8">
                                    <SemiColorfulText
                                        boldText="121151, г. Москва, Большая Дорогомиловская ул., д. 9 к. 2, этаж 1 ком. 33"
                                        basicText="Юридический адрес: "
                                    />
                                </div>
                                <div className="mb-8">
                                    <SemiColorfulText
                                        boldText="sales-bd9k2@yandex.ru"
                                        basicText="е-mail: "
                                    />
                                </div>
                                <div className="mb-8">
                                    <SemiColorfulText
                                        basicText="По вопросам приобретения офисов оставьте заявку: "
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="lg:pl-14">
                            <div className="mb-8 lg:mb-14">
                                <HeadText>Разрешительная <br/> документация</HeadText>
                            </div>
                            <div className="2xl:max-w-[500px]">
                                <div className="mb-8">
                                    <SemiColorfulText
                                        href={docs1}
                                        boldText="1. Договор Аренды земельного участка №М-07-042027 от 17.06.2013г. И ДС от 01.10.2021г."
                                    />
                                </div>
                                <div className="mb-8">
                                    <SemiColorfulText
                                        href={docs2}
                                        boldText="2. ГПЗУ РФ-77-4-53-3-18-2022-1719 от 09.03.2022г. "
                                    />
                                </div>
                                <div className="mb-8">
                                    <SemiColorfulText
                                        href={docs3}
                                        boldText="3. Свидетельство АГР №513-4-22/С от 07.06.22г."
                                    />
                                </div>
                                <div className="mb-8">
                                    <SemiColorfulText
                                        href={docs4}
                                        boldText="4. Разрешение на строительство 77-07-020543-2022 от 23.11.2022г."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="lg:ml-4 block max-w-[420px] max-h-[530px]" src={FooterImage}></img>
            </div>
        </div>
    );
}

export default FooterSection;
