import { useState } from "react";
import HeaderLogo from "../../images/logo.svg";
import PhoneIcon from "../../images/phone_header_icon.svg";
import EmailIcom from "../../images/email_header_icon.svg";
import MenuIcon from "../../svgs/burger.svg";
import CloseIcon from "../../svgs/close.svg";

function HeaderSection() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="bg-headerSection bg-cover w-full">
      <div className="bg-black bg-opacity-50">
        <div className="w-full h-[900px] padding-x py-12">
          <div className="flex h-full flex-col justify-between">
            <div className="flex sm:flex-row justify-between items-center">
              <div className="lg:w-auto mb-4 sm:mb-0">
                <img src={HeaderLogo} alt="Logo" />
              </div>
              <div>
                <div className="lg:flex hidden flex-row sm:justify-between sm:items-center">
                  <div className="flex flex-col lg:flex-row ">
                    <a
                      href="/#office"
                      className="text-white font-bold text-base mx-5 hover:scale-105 transition-all"
                    >
                      Офисы
                    </a>
                    <a
                      href="/#aboutProject"
                      className="text-white font-bold text-base mx-5 hover:scale-105 transition-all"
                    >
                      О проекте
                    </a>
                    <a
                      href="/#tech"
                      className="text-white font-bold text-base mx-5 hover:scale-105 transition-all"
                    >
                      Описание
                    </a>
                    <a
                      href="/#details"
                      className="text-white font-bold text-base mx-5 hover:scale-105 transition-all"
                    >
                      Детали
                    </a>
                  </div>
                  <div className="flex flex-col lg:flex-row">
                    <a
                      href="/#infro"
                      className="text-white font-bold text-base mx-5 hover:scale-105 transition-all"
                    >
                      Инфраструктура
                    </a>
                    <a
                      href="/#location"
                      className="text-white font-bold text-base mx-5 hover:scale-105 transition-all"
                    >
                      Расположение
                    </a>
                    <a
                      href="/#contact"
                      className="text-white font-bold text-base mx-5 hover:scale-105 transition-all"
                    >
                      Контакты
                    </a>
                  </div>
                  <div className="hidden ml-3">
                    <div>
                      <img className="h-5 w-5 mr-3" src={PhoneIcon} alt="Phone" />
                    </div>
                    <div>
                      <img className="h-5 w-5" src={EmailIcom} alt="Email" />
                    </div>
                  </div>
                </div>
                <div className="lg:hidden flex items-center">
                  <button
                    type="button"
                    className="text-white font-bold text-base mx-5 hover:scale-105 transition-all"
                    onClick={toggleMenu}
                  >
                    {isMenuOpen ? (
                      <img src={CloseIcon} alt="Close" className="h-5 w-5" />
                    ) : (
                      <img src={MenuIcon} alt="Menu" className="h-5 w-5" />
                    )}
                  </button>
                </div>
              </div>
            </div>
            {isMenuOpen && (
              <div className="lg:hidden">
                <div className="flex flex-col items-end mt-4">
                  <a
                    href="/#office"
                    className="text-white font-bold text-base mx-5 hover:scale-105 transition-all"
                  >
                    Офисы
                  </a>
                  <a
                    href="/#aboutProject"
                    className="text-white font-bold text-base mx-5 hover:scale-105 transition-all"
                  >
                    О проекте
                  </a>
                  <a
                    href="/#tech"
                    className="text-white font-bold text-base mx-5 hover:scale-105 transition-all"
                  >
                    Описание
                  </a>
                  <a
                    href="/#details"
                    className="text-white font-bold text-base mx-5 hover:scale-105 transition-all"
                  >
                    Детали
                  </a>
                  <a
                    href="/#infro"
                    className="text-white font-bold text-base mx-5 hover:scale-105 transition-all"
                  >
                    Инфраструктура
                  </a>
                  <a
                    href="/#location"
                    className="text-white font-bold text-base mx-5 hover:scale-105 transition-all"
                  >
                    Расположение
                  </a>
                  <a
                    href="/#contact"
                    className="text-white font-bold text-base mx-5 hover:scale-105 transition-all"
                  >
                    Контакты
                  </a>
                </div>
              </div>
            )}
            <div>
              <h1 className="text-[32px] sm:text-[48px] lg:text-[90px] font-bold text-white lg:leading-[96px] mb-7">
                Административно-деловой комплекс с подземным паркингом
              </h1>
              <p className="text-xl font-light text-white leading-[28px] mb-6">
                Уникальный деловой комплекс работы, вдохновленный роскошью
                московского ампира и функциональной архитектурой конструктивизма.
              </p>
              <p className="text-sm font-light text-white leading-[28px]">
                Большая Дорогомиловская, Д.9, К.2{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderSection;
