import BaseText from "./BaseText";

function ImageWithText({img,text, className}) {
    return (  
        <div className={`max-w-[738px] my-2 lg:mb-0 ${className}`}>
            <img className='lg:h-[460px] max-h-[460px] mb-4' src={img}></img>
            <BaseText opacity={true}>{text}</BaseText>
        </div>
    );
}

export default ImageWithText;