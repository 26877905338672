import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import SliderElement from "../base/SliderElement";

function BasicSlider({ images }) {

  const settings = {
    dots: true,
    autoplay: false,
    autoplaySpeed: 3000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight:true,
    speed: 500,
    appendDots: dots => (
      <div
        style={{
          paddingTop: "1%",
          scale: '2'
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };

  return (
    <div className>
      <Slider {...settings}>
        {images.map((imgs, key) => {
          return (
            <div className="lg:min-w-[800px]" key={key}>
              <SliderElement alt={imgs.alt} img={imgs.img} />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default BasicSlider;
