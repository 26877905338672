import BaseText from "../base/BaseText";
import HeadText from "../base/HeadText";

function BasicSection({ head, text, img, center }) {
    return (
        <div>
            <HeadText>{head}</HeadText>
            <div className="flex flex-col lg:flex-row justify-between items-center mt-10">
                <div className=" w-full lg:w-1/3 mb-8 lg:mb-0">
                    <BaseText opacity={true}>{text}</BaseText>
                </div>
                <img className="w-full lg:w-1/2" src={img}></img>
            </div>
        </div>
    );
}

export default BasicSection;
