import ImageWithText from "../base/ImageWithText";

import Details1 from "../../images/details1.png";
import Details2 from "../../images/details2.png";
import Details3 from "../../images/details3.png";
import Details4 from "../../images/details4.png";
import Details5 from "../../images/details5.png";
import Details6 from "../../images/details6.png";

function MultiImageSection() {
    const textDetail1 = (
        <>
            Латунные столики по периметру видовой площадки позволяют сделать
            паузу в любое время: остановиться, чтобы почитать или выпить кофе.
        </>
    );
    const textDetail2 = (
        <>
            На верхнем уровне можно провести недолгую встречу или отдохнуть в
            тени навеса. Зона напротив лобби — видовая площадка, с которой
            открывается вид на нижний двор. Каменные тумбы здесь —
            дополнительные места для отдыха.
        </>
    );
    const textDetail3 = (
        <>
            Благодаря натуральным материалам, мягкой подсветке и навигации, на
            территории комплекса приятно и комфортно находиться как резидентам,
            так и гостям.
        </>
    );
    const textDetail4 = (
        <>
            Амфитеатр на нижнем уровне может одновременно быть пространством как
            для встреч, так и для уединенного отдыха. Ступени амфитеатра
            выполнены из камня, а в теплое время года на нем будут располагаться
            дополнительные подушки.
        </>
    );
    const textDetail5 = (
        <>
            Внутренний двор дополняет внутреннее пространство комплекса — здесь также
            можно провести встречу или отдохнуть.
        </>
    );
    const textDetail6 = (
        <>
            Водная гладь и зелень успокаивают, создают ощущение уюта и
            умиротворения.
        </>
    );
    return (
        <div>
            <div className="flex flex-col lg:flex-row lg:justify-between items-center lg:items-start mb-0 lg:mb-12">
                <ImageWithText className='lg:pr-8 lg:w-[50%]' img={Details1} text={textDetail1} />
                <ImageWithText className='lg:pl-8 lg:w-[50%]' img={Details2} text={textDetail2} />
            </div>
            <div className="flex flex-col lg:flex-row lg:justify-between items-center lg:items-start mb-0 lg:mb-12">
                <ImageWithText className='lg:pr-8 lg:w-[50%]' img={Details3} text={textDetail3} />
                <ImageWithText className='lg:pl-8 lg:w-[50%]' img={Details4} text={textDetail4} />
            </div>
            <div className="flex flex-col lg:flex-row lg:justify-between items-center lg:items-start mb-0 lg:mb-12">
                <ImageWithText className='lg:pr-8 lg:w-[50%]' img={Details5} text={textDetail5} />
                <ImageWithText className='lg:pl-8 lg:w-[50%]' img={Details6} text={textDetail6} />
            </div>
        </div>
    );
}

export default MultiImageSection;
