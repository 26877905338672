function DoubleText({head,text}) {
    return (  
        <div className="max-w-[520px] flex flex-col mb-4 lg:mb-12">
            <div className="text-black text-[16px] lg:text-[24px] leading-[28px] font-bold mb-2">
                {head}
            </div>
            <div className="text-[14px] lg:text-[20px] font-medium text-black opacity-40"> 
                {text}
            </div>
        </div>
    );
}

export default DoubleText;