import React, { useState } from "react";

import Button from "../base/Button";
import HeadText from "../base/HeadText";
import InputArea from "../base/InputArea";
import InputBox from "../base/InputBox";

function FeedBackSection() {
    const [userEmail, setEmail] = useState("");
    const [userName, setName] = useState("");
    const [userPhone, setPhone] = useState("");
    const [userMessage, setMessage] = useState("");

    async function sendInfo() {
        // Проверка email с использованием регулярного выражения
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(userEmail)) {
            alert("Неправильный формат email");
            return;
        }
    
        // Проверка телефона с использованием регулярного выражения
        const phoneRegex = /^(\+?7)?\d{10,12}$/;
        if (!phoneRegex.test(userPhone)) {
            alert("Неправильный формат телефона");
            return;
        }
    
        const title = "Лид форма по Buisness Village";
    
        const message = `Новое обращение Buisness Village!\n\nИмя: ${userName}\n\nEmail: ${userEmail}\n\nТелефон: ${userPhone}\n\nСообщение: ${userMessage}`;
        const login = "bd9k2.info@gmail.com";
        const password = "isuzbhnymhqqhxkc";
        const to_mail = 'info-bd9k2@yandex.ru';
    
        const requestOptions = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                title,
                message,
                login,
                password,
                to_mail,
            })
        };
    
        // Отправка данных
        try {
            const response = await fetch('https://tools.cleverra.ru/send_mail', requestOptions);
            if (response.ok) {
                alert("Данные успешно отправлены. В скором времени вы получите обратную связь!");
            } else {
                alert("Произошла ошибка при отправке данных. Пожалуйста, попробуйте позже.");
            }
        } catch (error) {
            console.error("Ошибка при отправке данных:", error);
            alert("Произошла ошибка при отправке данных. Пожалуйста, попробуйте позже.");
        }
    }
    
    
    

    return (
        <div>
            <div className="mb-12">
                <HeadText>Обратная связь</HeadText>
            </div>
            <div>
                <InputBox
                    label="E-mail"
                    type="text"
                    placeholder="Введите ваш e-mail"
                    value={userEmail}
                    onChange={(e) => setEmail(e.target.value)} // Измените обработчик события
                />
                <InputBox
                    label="Имя"
                    type="text"
                    placeholder="Введите ваше имя"
                    value={userName}
                    onChange={(e) => setName(e.target.value)} // Измените обработчик события
                />
                <InputBox
                    label="Телефон"
                    type="text"
                    placeholder="Введите ваш телефон"
                    value={userPhone}
                    onChange={(e) => setPhone(e.target.value)} // Измените обработчик события
                />
                <InputArea
                    label="Задайте свой вопрос"
                    type="text"
                    placeholder="Введите свой вопрос"
                    value={userMessage}
                    onChange={(e) => setMessage(e.target.value)} // Измените обработчик события
                />
                <Button onClick={sendInfo} text="Отправить ваш вопрос" />
            </div>
        </div>
    );
}

export default FeedBackSection;
