import HeaderSection from "./components/sections/HeaderSection";
import BasicSection from "./components/sections/BasicSection";
import TechSection from "./components/sections/TechSection";
import MultiImageSection from "./components/sections/MultiImageSection";
import BasicSectionMultyHeaders from "./components/sections/BasicSectionMultyHeaders";
import HeadText from "./components/base/HeadText";
import FooterSection from "./components/sections/FooterSection";
import FeedBackSection from "./components/sections/FeedBackSection";

import BasicSlider from "./components/sliders/BasicSlider";

// Импорт картинок

import BaseOfficeImg from "../src/images/offices.png";
import AbousUsImg from "../src/images/about2.png";
import StructureImg from "../src/images/about3.png";
import DesignImg from "../src/newImages/room.jpg";
import HistorySectionImg from '../src/newImages/placeHistoryNew.jpg'

import Office2 from "../src/newImages/doubleImage1.jpg";
import Office3 from "../src/images/offices3.png";

import SoloImage1 from "../src/images/big2.png";
import SoloImage2 from "../src/images/big4.png";
import SoloImage3 from "../src/newImages/SoloImageNew1.jpg";
import SoloImage4 from "../src/newImages/parking.jpg";
import SoloImage5 from "../src/images/big8.png";
import SoloImage6 from "../src/images/big9.png";

import HistoryImage1 from "../src/images/big10.png";
import HistoryImage2 from "../src/images/big11.png";

import HistoryTimeline1 from '../src/images/history1.png'
import HistoryTimeline2 from '../src/images/history4.png'

import ThirdMultiImgSection1 from "../src/images/about5.png";
import ThirdMultiImgSection2 from "../src/images/about6.png";
import ThirdMultiImgSection3 from "../src/images/about7.png";

import SecondThirdMultiImgSection1 from "../src/images/history5.png";
import SecondThirdMultiImgSection2 from "../src/images/history6.png";
import SecondThirdMultiImgSection3 from "../src/images/history7.png";

import FullSoloImage1 from "../src/newImages/greenMap.jpg";

// Импорт картинок для слайдеров
import FirstSliderImage2 from "../src/images/sliderImages2_0/1.1.jpg";
import FirstSliderImage3 from "../src/images/sliderImages2_0/1.2.jpg";
import FirstSliderImage4 from "../src/images/sliderImages2_0/1.3.jpg";
import FirstSliderImage5 from "../src/images/sliderImages2_0/1.4.jpg";

import TwoSliderImage1 from "../src/images/sliderImages2_0/2.1.jpg";
import TwoSliderImage2 from "../src/images/sliderImages2_0/2.2.jpg";
import TwoSliderImage3 from "../src/images/sliderImages2_0/2.3.jpg";

import TreeSliderImage1 from "../src/images/sliderImages2_0/3.1.jpg";
import TreeSliderImage2 from "../src/images/sliderImages2_0/3.2.jpg";
import TreeSliderImage3 from "../src/images/sliderImages2_0/3.3.jpg";
import TreeSliderImage4 from "../src/images/sliderImages2_0/3.4.jpg";
import TreeSliderImage5 from "../src/images/sliderImages2_0/3.5.jpg";

// Импорт более качественных картинок

function App() {
    const firstSliderImages = [
        {
            img: FirstSliderImage2,
            alt: "img2",
        },
        {
            img: FirstSliderImage3,
            alt: "img3",
        },
        {
            img: FirstSliderImage4,
            alt: "img4",
        },
        {
            img: FirstSliderImage5,
            alt: "img5",
        },
    ];
    const twoSliderImages = [
        {
            img: TwoSliderImage1,
            alt: "img1",
        },
        {
            img: TwoSliderImage2,
            alt: "img2",
        },
        {
            img: TwoSliderImage3,
            alt: "img3",
        },
    ];
    const threeSliderImages = [
        {
            img: TreeSliderImage1,
            alt: "img1",
        },
        {
            img: TreeSliderImage2,
            alt: "img2",
        },
        {
            img: TreeSliderImage3,
            alt: "img3",
        },
        {
            img: TreeSliderImage4,
            alt: "img4",
        },
        {
            img: TreeSliderImage5,
            alt: "img5",
        },
    ];
    const officeTextSection = (
        <>
            Комплекс представляет возможности разных офисных пространств – от
            высоких двухуровневых с отдельным входом до более компактных – с
            общим входом на втором уровне .
            <br />
            <br />
            Планировки этажей соответствуют требованиям функционального офиса –
            глубина помещений, высота потолков, шаг колонн. Окна в пол обеспечат
            оптимальный уровень инсоляции и предусматривают возможность
            естественного проветривания.
            <br />
            <br />
            Для многих офисов предусмотрены террасы.
            <br />
            <br />
            Лоты подбираются в зависимости от потребностей вашего бизнеса и в
            стандартной комплектации идут без отделки, интерьер дизайн и отделка
            возможна по запросу.
        </>
    );
    const aboutProjectSection = (
        <>
            Уникальный деловой комплекс и спа, вдохновленный роскошью
            московского ампира и функциональной архитектурой конструктивизма.
            <br />
            <br />
            Совмещая бизнес-пространство с зонами отдыха и общения, Sunday
            продолжает традиции одного из самых престижных и парадных районов
            Москвы, где когда-то возводились дома для государственной и
            культурной элиты СССР.
            <br />
            <br />
            Закрытая территория комплекса создает комфортные условия для работы
            в самом центре мегаполиса.
        </>
    );
    const detailsSection = (
        <>
            Внутренний двор сформирован на двух уровнях — верхнем, более
            публичном, нижнем — более приватном, с продуманным зонированием под
            разные сценарии использования и элементами уличной мебели для
            непринужденного отдыха или работы.
            <br />
            <br />
            Такое необычное архитектурное решение дополняют качественные и
            продуманные до мелочей детали и материалы комплекса.
        </>
    );
    const StructureSection = (
        <>
            В стилобате разместится вся необходимая инфраструктура для
            оптимального функционирования бизнес-центра и удовлетворения
            потребностей резидентов.
            <br />
            <br />
            Совмещая бизнес-пространство с зонами отдыха и общения, данный
            проект продолжает традиции одного из самых престижных и парадных
            районов Москвы, где когда-то возводились здания для государственной
            и культурной элиты СССР.
            <br />
            <br />
            Деловой комплекс с представительскими смарт-офисами и шоу-румами
            дополняет необходимую инфраструктуру района планируемым СПА с
            бассейном и косметологией, спортзалом, центром йоги, медцентром,
            кафе, помещениями для коворкинга, студией изобразительных искусств,
            пространствами дискуссионного клуба, зонами отдыха и общения для
            посетителей и сотрудников.
            <br />
            <br />
            Закрытая территория делового комплекса представляет собой единую
            пространственную структуру, состоящую из офисных блоков широкой
            функциональности, и создает комфортные условия для работы. Объект
            становится камерным и вписывается в сетку существующей застройки
            дворового типа без ущерба для окружения за счет малой этажности.
        </>
    );
    const DesignSection = (
        <>
            Дизайн-проект отвечает современным европейским трендам и базируется
            на естественном свете, натуральных тактильных материалах и принципах
            биофильного дизайна, которые призваны поддерживать психологический
            комфорт резидентов и гостей. Продуманное зонирование и система
            навигации грамотно распределяют движение посетителей и создают
            дополнительные функциональные пространства для коммуникаций и отдыха
            резидентов в лобби и во внутреннем дворе .
            <br />
            <br />
            Подземный паркинг рассчитан на необходимое количество для всех
            пользователей, и гарантирует отсутсвие парковки на улице, не
            создавая нагрузки на существующий квартал.
            <br />
            <br />
            <br />
            Ресепшн 24/7
        </>
    );
    const HistorySection = (
        <>
            История Дорогомилово берет начало в 18 веке. За свое долгое и
            богатое на события существование, роль этой территории много раз
            менялась: от ямской слободы к промышленному центру, от статусного
            советского района к современному деловому и торговому кластеру в
            центре столицы.
            <br />
            <br />
            Современный облик района определил Генеральный план реконструкции
            Москвы от 1935 года. Тогда было принято решение о строительстве
            широкой сети дорог и создании протяженных жилых кварталов.
            Дорогомиловский район застраивался для партийной и культурной элиты
            СССР. Дома строили единым ансамблем, параллельно создавая
            необходимую инфраструктуру района. Так возникла эта парадная и
            престижная территория в центре Москвы.
            <br />
            <br />
            Два архитектурных стиля, воплотившиеся в этом районе: сталинский
            ампир и конструктивизм.
        </>
    );
    return (
        <div className="overflow-x-hidden">
            <div>
                <HeaderSection />
            </div>
            <div id="office" className="padding-all">
                <BasicSection
                    head="Офисы"
                    text={officeTextSection}
                    img={BaseOfficeImg}
                />
            </div>
            <div className="padding-all">
                <BasicSlider images={firstSliderImages} />
            </div>
            <div className="flex flex-col lg:flex-row justify-center items-center lg:padding-all">
                <img className="lg:mr-12 mb-4 lg:mb-0 lg:max-w-[480px] 2xl:max-w-none max-h-[650px]" src={Office2}></img>
                <img className="lg:max-w-[480px] 2xl:max-w-none max-h-[650px]" src={Office3}></img>
            </div>
            <div id="aboutProject" className="padding-all">
                <BasicSection
                    head="О проекте"
                    text={aboutProjectSection}
                    img={AbousUsImg}
                />
            </div>
            <div className="padding-all flex items-center justify-center">
                <img src={SoloImage1}></img>
            </div>
            <div className="padding-all">
                <BasicSlider images={twoSliderImages} />
            </div>
            <div className="padding-y">
                <img className="w-full" src={FullSoloImage1}></img>
            </div>
            <div id="tech" className="padding-all">
                <TechSection></TechSection>
            </div>
            <div className="padding-y">
                <img className="w-full" src={SoloImage2}></img>
            </div>
            <div id="details" className="padding-all">
                <BasicSection head="Детали" text={detailsSection} />
            </div>
            <div className="padding-all">
                <MultiImageSection />
            </div>
            <div className="padding-all">
                <BasicSlider images={threeSliderImages} />
            </div>
            <div id="infro" className="padding-all">
                <BasicSection
                    head="Инфраструктура"
                    text={StructureSection}
                    img={StructureImg}
                    center={true}
                />
            </div>
            <div className="padding-y">
                <img src={SoloImage3}></img>
            </div>
            <div className="padding-all">
                <BasicSection
                    text={DesignSection}
                    img={DesignImg}
                    center={true}
                />
            </div>
            <div className="padding-y">
                <img src={SoloImage4}></img>
            </div>
            <div className="padding-y">
                <img className="w-full" src={SoloImage5}></img>
            </div>
            <div className="flex flex-col 2xl:flex-row justify-center items-center padding-all">
                <img
                    className="px-4 h-[747px] max-w-[560px] 2xl:mb-0 mb-4"
                    src={ThirdMultiImgSection2}
                ></img>
                <img
                    className="px-4 h-[747px] max-w-[560px] 2xl:mb-0 mb-4"
                    src={ThirdMultiImgSection1}
                ></img>
                <img
                    className="px-4 h-[747px] max-w-[560px]"
                    src={ThirdMultiImgSection3}
                ></img>
            </div>
            <div className="padding-y">
                <img className="w-full" src={SoloImage6}></img>
            </div>
            <div className="padding-all">
                <BasicSectionMultyHeaders
                    head="История места"
                    semihead="Знаковая архитектура"
                    text={HistorySection}
                    img={HistorySectionImg}
                />
            </div>
            <div className="padding-all">
                <img className="w-full" src={HistoryImage1}></img>
            </div>
            <div className="padding-y">
                <img className="w-full" src={HistoryImage2}></img>
            </div>
            <div className='hidden'>
                <div className="padding-all mb-10">
                    <HeadText>История места</HeadText>  
                </div>
                <div className="hidden items-center relative">
                    <img src={HistoryTimeline1}></img>
                    <img className="absolute left-[50%] bottom-[0.25%]" src={HistoryTimeline2}></img>
                </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-center items-center padding-all">
                <img
                    className="px-2 py-2 lg:py-0"
                    src={SecondThirdMultiImgSection1}
                ></img>
                <img
                    className="px-2 py-2 lg:py-0"
                    src={SecondThirdMultiImgSection2}
                ></img>
                <img
                    className="px-2 py-2 lg:py-0"
                    src={SecondThirdMultiImgSection3}
                ></img>
            </div>
            <div id="location" className="padding-all">
                <div className="mb-16">
                    <HeadText>Расположение</HeadText>
                </div>
                <iframe src="https://yandex.kz/map-widget/v1/?ll=37.556004%2C55.745765&mode=usermaps&source=constructorLink&um=constructor%3Ad283cf2b28968c59ff8cc699fb95b70caae198fcd903e49dd526f816ff60de46&z=15" width="100%" height="700px" frameborder="1" allowfullscreen="true"></iframe>
            </div>
            <div id="contact" className="padding-all">
                <FooterSection/>
            </div>
            <div className="padding-all">
                <FeedBackSection/>
            </div>
        </div>
    );
}

export default App;
