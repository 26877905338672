import React from 'react';

function Button({text, onClick}) {
  return (
    <button onClick={onClick} className="bg-white border-2 border-black text-[24px] px-8 py-4  text-black hover:bg-black hover:text-white transition duration-300 ease-in-out">
      {text}
    </button>
  );
}

export default Button;
