import BaseText from "../base/BaseText";
import HeadText from "../base/HeadText";

function BasicSectionMultyHeaders({ head, semihead, text, img, center }) {
    return (
        <div className={`flex h-full items-center lg:items-start lg:flex-row flex-col justify-between ${center? "lg:!items-center" : ""}`}>
            <div className='flex h-full flex-col justify-between pb-12 mr-4'>
                <div className="mb-8 2xl:mb-16">
                    <HeadText>{head}</HeadText>
                </div>
                <div className="lg:w-[35vw]">
                    <div className='mb-14'>
                        <h2 className="text-[22px] lg:text-[32px] leading-[28px] font-bold opacity-60">{semihead}</h2>
                    </div>
                    <div>
                        <BaseText opacity={true}>
                            {text}
                        </BaseText>
                    </div>
                </div>
            </div>
            <div className="lg:w-[40vw] flex justify-end">
                <img className={img ? "block" : "hidden"} src={img}></img>
            </div>
        </div>
    );
}

export default BasicSectionMultyHeaders;
